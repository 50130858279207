import { useEffect, useRef, useState } from 'react';

export const calculateTop = (accordionContentHeight = 0) => {
  const sectionRef = useRef(null);
  const [sectionTop, setSectionTop] = useState(0);

  useEffect(() => {
    if (sectionRef.current) {
      if (sectionRef.current.offsetHeight > window.innerHeight) {
        setSectionTop(
          window.innerHeight - sectionRef.current.offsetHeight - accordionContentHeight
        );
      } else {
        setSectionTop(0);
      }
    }
  }, [sectionRef.current, accordionContentHeight]);
  return {
    sectionRef,
    sectionTop,
  };
};
