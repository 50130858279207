import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTo = () => {
  const [isOpenInfoSection, setIsOpenInfoSection] = useState(false);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '#info') {
      setTimeout(() => {
        const elem = document.getElementById('infoSection');
        window.scrollTo(0, elem.offsetTop - 300);
      }, 300);
    }
  }, [isOpenInfoSection]);

  const scrollTo = (context, offset = 0, behavior = 'smooth') => {
    const target = document.getElementById(context);
    if (target && typeof window !== 'undefined') {
      window.scrollTo({ top: target.offsetTop + offset, behavior });
    }
  };

  return {
    scrollTo,
    setIsOpenInfoSection,
  };
};
