/* eslint-disable prettier/prettier */
import downloadImg from '../../../assets/info/download.png';
import ios from '../../../assets/info/IOS.pdf'
import ruStoreImg from '../../../assets/info/RuStore.png';

export const FOOTER_BUTTONS = [
  {
    title: 'Приложение администратора',
    img: ruStoreImg,
    link: 'https://apps.rustore.ru/app/com.nachaiadminnative'
  },
  {
    title: 'Приложение для персонала',
    img: ruStoreImg,
    link: 'https://apps.rustore.ru/app/com.waiter_mobile_app'
  },
  {
    title: 'Установка на IOS',
    img: downloadImg,
    link: ios
  },
];
