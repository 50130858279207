import React from 'react';
import ReactDOM from 'react-dom';

import checkmark from '../../assets/modal/checkmark.svg';
import styles from './modal.module.scss';

const Modal = ({ isVisible, close }) =>
  isVisible
    ? ReactDOM.createPortal(
        <>
          <div className={styles.modalOverlay} />
          <div className={styles.modalWrapper} tabIndex={-1} role="dialog">
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <div className={styles.checkmark}>
                  <img src={checkmark} alt="checkmark" />
                </div>
                <div className={styles.modalInfo}>
                  <h3>Спасибо за обращение!</h3>
                  <p>Наши менеджеры свяжутся с вами в ближайшее время</p>
                </div>
              </div>
              <div className={styles.modalButtonWrapper}>
                <button type="button" className={styles.modalCloseButton} onClick={close}>
                  <span>Хорошо</span>
                </button>
              </div>
            </div>
          </div>
        </>,
        document.querySelector('#modal')
      )
    : null;
export default Modal;
