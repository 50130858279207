import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

import styles from './button.module.scss';

const Button = React.memo(({ variant, color, label, useThisStyle = false, ymEvent }) => {
  const { scrollYProgress } = useScroll();
  const backgroundColor = useTransform(scrollYProgress, [0, 0.1], ['#7a64e4', '#eceff5']);
  const textColor = useTransform(scrollYProgress, [0, 0.1], ['#ffffff', '#18181b']);
  const boxShadow = useTransform(
    scrollYProgress,
    [0, 0.1],
    [
      '-10px -10px 30px #9B87FA, 10px 10px 30px #3E2AA3',
      '-10px -10px 30px rgba(255, 255, 255, 0.8), 10px 10px 30px rgba(0, 0, 0, 0.2)',
    ]
  );
  return (
    <div className={`${styles[`${variant}Btn`]}  ${styles[`${color}`]}`} onClick={ymEvent}>
      <motion.a
        href={process.env.REACT_APP_LK_ADMIN_URL}
        target="_blank"
        className={`${styles.buttonLabel} buttonLabel`}
        rel="noreferrer"
        style={
          useThisStyle
            ? {
                backgroundColor,
                color: textColor,
                boxShadow,
                transition: '0.2s all',
              }
            : {}
        }
      >
        {label}
      </motion.a>
    </div>
  );
});

Button.displayName = 'Button';

export default Button;
