import React from 'react';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/tenqLg.svg';
import styles from './errorPage.module.scss';

const ErrorPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.logo} src={logoImg} alt="tenqlogo" />
        <h1 className={styles.title}>Что-то пошло не так</h1>
        <div>
          <p className={styles.text}>Этой страницы не существует</p>
          <p className={styles.text}>Попробуйте вернуться на главную</p>
        </div>

        <Link to="/" className={styles.button}>
          <p>Вернуться на главную</p>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
