import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  return (
    <div>
      <CookieConsent buttonText="Принять и продолжить" expires={356}>
        Пользуясь нашим сайтом, вы соглашаетесь с тем, что{' '}
        <Link to="/policy" style={{ color: '#FFFFFF', fontWeight: '300' }}>
          <span>мы используем cookies</span>
        </Link>
      </CookieConsent>
    </div>
  );
};

export default CookieBanner;
