import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Logo } from '../../../assets/Logo';
import { useScrollTo } from '../../../hooks/useScrollTo';
import Contact from '../../sections/contact/Contact';
import FAQ from '../../sections/faq/FAQ';
import { FOOTER_BUTTONS } from './constants';
import styles from './footer.module.scss';

const Footer = () => {
  const { setIsOpenInfoSection } = useScrollTo();
  const handleClick = () => {
    document.getElementById('contact').style.position = 'absolute';
    document.getElementById('contact').style.top = `${window.innerHeight}px`;
    setTimeout(() => {
      document.getElementById('contact').style.position = 'sticky';
      document.getElementById('contact').style.top = `-${window.innerHeight}px`;
    }, 300);
  };
  const { pathname } = useLocation();
  const subscribeVisitor = () => {
    fetch(`${process.env.REACT_APP_TENQ_API}/api/subscribeVisitor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        window.open(data.data, '_blank');
      })
      .catch((error) => console.error('Ошибка:', error));
  };
  return (
    <>
      {pathname !== '/terms' &&
      pathname !== '/agreement' &&
      pathname !== '/policy' &&
      pathname !== '/info' ? (
        <FAQ />
      ) : null}
      <footer
        id="contact"
        className={styles.footer}
        style={
          pathname !== '/terms' &&
          pathname !== '/agreement' &&
          pathname !== '/policy' &&
          pathname !== '/info'
            ? { minheight: '100vh', height: '100%' }
            : {}
        }
      >
        {pathname !== '/terms' &&
        pathname !== '/agreement' &&
        pathname !== '/policy' &&
        pathname !== '/info' ? (
          <Contact />
        ) : null}
        <div className="container">
          <div className={styles.footerContent}>
            <div className={styles.leftSide}>
              <div className={styles.logo}>
                <Logo />
              </div>
              <div className={styles.buttonsWrapper}>
                {FOOTER_BUTTONS.map((btn) => (
                  <div key={btn.title}>
                    <p className={styles.buttonTitle}>{btn.title}</p>
                    <a
                      href={btn.link}
                      className={styles.footerLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {btn?.img && <img src={btn.img} alt="footer button" />}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.infoLinks}>
              <div className={styles.info}>
                <h3>О сервисе</h3>
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = '/#info';
                        setIsOpenInfoSection((val) => !val);
                      }}
                    >
                      Почему 10Q
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = '/#flow';
                        setIsOpenInfoSection((val) => !val);
                      }}
                    >
                      Как это работает
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        window.location.href = '/#features';
                        setIsOpenInfoSection((val) => !val);
                      }}
                    >
                      Возможности
                    </a>
                  </li>
                  <li role="presentation" onClick={handleClick}>
                    <a
                      onClick={() => {
                        window.location.href = '/#faq';
                        setIsOpenInfoSection((val) => !val);
                      }}
                    >
                      FAQ
                    </a>
                  </li>
                  <li role="presentation" onClick={subscribeVisitor}>
                    <a>Техническая поддержка</a>
                  </li>
                </ul>
              </div>
              <div className={styles.info}>
                <h3>Контакты</h3>
                <ul>
                  <li>
                    <a href="tel:+79688614231">+7 968 861 4231</a>
                  </li>
                  <li>
                    <a href="mailto:support@tenq.ru">support@tenq.ru</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.copyright}>
            <p>Copyright {new Date().getFullYear()} 10Q</p>
            <Link to="/info">О программе</Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
