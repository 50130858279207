import React, { Suspense } from 'react';

const Design = React.lazy(() => import('../sections/design/Design'));
const Features = React.lazy(() => import('../sections/features/Features'));
const Flow = React.lazy(() => import('../sections/flow/Flow'));
const Info = React.lazy(() => import('../sections/info/Info'));

const Home = React.memo(() => {
  return (
    <Suspense>
      <Info />
      <Flow />
      <Features />
      <Design />
    </Suspense>
  );
});

Home.displayName = 'Home';

export default Home;
