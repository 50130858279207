export const FAQ_LIST = [
  {
    id: 1,
    title: 'Как подключить заведение к 10Q?',
    description:
      'Вам достаточно зарегистрировать ваше заведение в системе 10Q и связаться с нашими менеджерами для заключения договора',
    isOpen: false,
  },
  {
    id: 2,
    title: 'Сколько времени займет внедрение?',
    description:
      'Вы можете в течение нескольких минут зарегистрировать ваше заведение и приступить к интеграции с R-keeper',
    isOpen: false,
  },
  {
    id: 3,
    title: 'Как настроить интеграцию с R-keeper?',
    description:
      'В системе 10Q вы найдете необходимые инструкцию по подключению R-keeper, и наши специалисты смогут проконсультировать вас по всем шагам настройки',
    isOpen: false,
  },
  {
    id: 4,
    title: 'Какие возможны способы оплаты?',
    description: 'Клиенты могут осуществить платеж с помощью банковской карты или через СБП',
    isOpen: false,
  },
  {
    id: 5,
    title: 'Почему подключение к системе 10Q бесплатно?',
    description: '10Q получает небольшой процент с каждой транзакции от банка-эквайринга',
    isOpen: false,
  },
];
