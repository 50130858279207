import React, { useState } from 'react';
import { useEffect } from 'react';

import processingOfPersonalData from '../../../assets/about/processing_of_personal_data.pdf';
import send from '../../../assets/contact/send.svg';
import useModal from '../../../hooks/useModal';
import Modal from '../../modal/Modal';
import CheckBox from '../../ui/checkbox/Checkbox';
import styles from './contact.module.scss';

const Contact = React.memo(() => {
  const [email, setEmail] = useState('');
  const [ckecked, setChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const active = ckecked ? `${styles.active}` : '';

  const { isVisible, toggle } = useModal();

  const sendEmail = async () => {
    if (ckecked) {
      if (!email) {
        setErrorMessage('Обязательное поле');
      } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        const response = await fetch(
          `${process.env.REACT_APP_TENQ_API}/api/v0/send-callback-form?email=${email}`
        );
        const data = await response.json();
        if (data.result === 'success') {
          toggle();
          setEmail('');
          setChecked(false);
        }
      } else {
        setErrorMessage('Некорректный адрес электронной почты');
      }
    }
  };

  useEffect(() => {
    if (errorMessage !== '') {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
    if (successMessage !== '') {
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  }, [errorMessage, successMessage]);

  return (
    <section className={styles.contactSection}>
      <div className="container">
        <div className={styles.heading}>
          <h1 className={styles.title}>Не нашли что искали?</h1>
          <p className={styles.description}>Оставьте вашу почту и мы ответим на все ваши вопросы</p>
        </div>
        <div className={styles.subscribe}>
          <div>
            <input
              className={styles.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="your@email.com"
              type="text"
            />
          </div>
          <div className={`${styles.sendBtn} ${active}`} onClick={sendEmail}>
            <img src={send} alt="send" />
          </div>
        </div>
        <div className={styles.message}>
          <p className={styles.error}>{errorMessage}</p>
          <p className={styles.success}>{successMessage}</p>
        </div>
        <div className={styles.checkbox}>
          <CheckBox ckecked={ckecked} onChange={() => setChecked(!ckecked)} />
          <div onClick={() => setChecked(!ckecked)}>
            Я ознакомился и согласен с{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              href={require('../../../assets/agreements/Terms_of_use.pdf')}
              download="Пользовательское соглашение"
            >
              Пользовательским соглашением
            </a>{' '}
            {/* <Link
              to="/terms"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Пользовательским соглашением
            </Link>{' '} */}
            и{' '}
            <a
              onClick={(e) => e.stopPropagation()}
              download="Политика обработки персональных данных.pdf"
              href={processingOfPersonalData}
            >
              Политикой обработки данных
            </a>{' '}
            {/* <Link
              to="/agreement"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Политикой обработки данных
            </Link> */}
          </div>
        </div>
      </div>
      <Modal isVisible={isVisible} close={toggle} />
    </section>
  );
});

Contact.displayName = 'Contact';

export default Contact;
