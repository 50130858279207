import React from 'react';

import styles from './checkbox.module.scss';

const CheckBox = React.memo(({ ckecked, onChange }) => {
  return (
    <div className={`${styles.wrapper}`} onClick={onChange}>
      <div
        className={ckecked ? `${styles.check} ${styles.checkActive}` : styles.check}
        style={{ background: ckecked ? '#7A64E4' : '#939399' }}
      >
        {ckecked && (
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 4.5L5.5 8.5L12.5 1.5" stroke="white" strokeWidth="2" />
          </svg>
        )}
      </div>
    </div>
  );
});

CheckBox.displayName = 'CheckBox';

export default CheckBox;
