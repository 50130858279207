import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ym from 'react-yandex-metrika';

import { Logo } from '../../../assets/Logo';
import { useScrollTo } from '../../../hooks/useScrollTo';
import Button from '../../ui/button/Button';
import styles from './header.module.scss';

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const { scrollTo, setIsOpenInfoSection } = useScrollTo();
  const { pathname, hash } = useLocation();
  const { scrollYProgress } = useScroll();

  useEffect(() => {
    if (pathname === '/terms' || pathname === '/agreement') {
      document.documentElement.style.scrollBehavior = 'initial';
      document.body.style.scrollBehavior = 'initial';
    }
    setTimeout(() => {
      scrollTo(hash.replace('#', ''));
    }, 1000);
  }, [hash]);

  useEffect(() => {
    if (showNavbar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [showNavbar]);

  const menuOpen = () => {
    setShowNavbar(!showNavbar);
  };

  const changeSection = () => {
    setTimeout(() => {
      setShowNavbar(false);
    }, 500);
  };

  const handleClick = () => {
    document.getElementById('contact').style.position = 'absolute';
    document.getElementById('contact').style.top = `${window.innerHeight}px`;
    setTimeout(() => {
      document.getElementById('contact').style.position = 'sticky';
      document.getElementById('contact').style.top = `-${window.innerHeight}px`;
    }, 300);
  };

  const isMenuOpen = showNavbar ? `${styles.active}` : '';
  const backgroundColor = useTransform(scrollYProgress, [0, 0.1], ['transparent', '#ECEFF5']);

  return (
    <motion.header
      style={{
        zIndex: showNavbar ? '99999' : '99997',
        backgroundColor,
        transition: '0.2s all',
      }}
    >
      <div className="container">
        <div className={`${styles.headerContent} ${isMenuOpen}`}>
          <a href="/" className={styles.logo} aria-label="nav">
            <Logo showNavbar={showNavbar} />
          </a>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              <li>
                <a
                  onClick={() => {
                    window.location.href = '/#info';
                    setIsOpenInfoSection((val) => !val);
                    changeSection();
                  }}
                >
                  Почему 10Q
                </a>
              </li>
              <li>
                <a href="/#flow" onClick={changeSection}>
                  Как это работает
                </a>
              </li>
              <li>
                <a href="/#features" onClick={changeSection}>
                  Возможности
                </a>
              </li>
              <li role="presentation" onClick={handleClick}>
                <a href="/#faq" onClick={changeSection}>
                  FAQ
                </a>
              </li>
              <li>
                <a href="/#contact" onClick={changeSection}>
                  Контакты
                </a>{' '}
              </li>
            </ul>
          </nav>
          <div className={styles.buttons}>
            {!showNavbar ? (
              <Button
                variant="default"
                color="gray"
                label="Войти"
                useThisStyle={
                  pathname !== '/terms' &&
                  pathname !== '/agreement' &&
                  pathname !== '/policy' &&
                  pathname !== '/info'
                }
                ymEvent={() => ym('reachGoal', 'login')}
              />
            ) : (
              <Button
                variant="default"
                color="gray"
                label="Войти"
                ymEvent={() => ym('reachGoal', 'login')}
              />
            )}

            <div className={styles.hamburger} onClick={menuOpen}>
              <div className={styles.bar} />
              <div className={styles.bar} />
              <div className={styles.bar} />
            </div>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
