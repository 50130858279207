// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox_wrapper__eKlf- {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.checkbox_check__x0Awj {\n  width: 24px !important;\n  height: 24px !important;\n  border-radius: 8px;\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/checkbox/checkbox.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAGA;EACE,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":["@import \"src/styles/pallete\";\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.check {\n  width: 24px !important;\n  height: 24px !important;\n  border-radius: 8px;\n  margin-right: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "checkbox_wrapper__eKlf-",
	"check": "checkbox_check__x0Awj"
};
export default ___CSS_LOADER_EXPORT___;
