import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useCallback } from 'react';

import close from '../../../assets/faq/close.svg';
import open from '../../../assets/faq/open.svg';
import { FAQ_LIST } from '../../../constants/faq';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { calculateTop } from '../../../utils/calculateTop';
import styles from './faq.module.scss';

const FAQ = React.memo(() => {
  const [faqList, setFaqList] = useState(FAQ_LIST);
  const [accordionContentHeight, setAccordionContentHeight] = useState(0);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { sectionRef, sectionTop } = calculateTop(accordionContentHeight);

  const handleChange = useCallback((e, id) => {
    setAccordionContentHeight(e.target.nextSibling.firstChild.offsetHeight);
    setFaqList((prev) =>
      prev.map((item) => {
        return item.id === id ? { ...item, isOpen: !item.isOpen } : { ...item, isOpen: false };
      })
    );
  }, []);

  const handleAnimation = (item, isOpen = true) => {
    if (isOpen) {
      return faqList.find((el) => !el.isOpen && el.id === item.id);
    }
    return faqList.find((el) => el.isOpen && el.id === item.id);
  };

  return (
    <section id="faq" ref={sectionRef} className={styles.faqSection} style={{ top: sectionTop }}>
      <div className="container">
        <div className={styles.heading}>
          <h1 className={styles.title}>Вопрос - ответ</h1>
        </div>
        <div className={styles.faqList}>
          {faqList.map((item) => (
            <div
              key={item.id}
              className={item.isOpen ? `${styles.listItem} ${styles.active}` : `${styles.listItem}`}
            >
              <div className={styles.acordionController} onClick={(e) => handleChange(e, item.id)}>
                <h3 className={styles.title}>{item.title}</h3>
                <div className={styles.imgContainer}>
                  <motion.img
                    src={close}
                    alt="close"
                    initial={{ opacity: 0, rotate: 0 }}
                    animate={{
                      opacity: handleAnimation(item, false) ? 1 : 0,
                      rotate: handleAnimation(item, false) ? 0 : 45,
                    }}
                    transition={{ duration: 0.4 }}
                  />
                  <motion.img
                    src={open}
                    alt="open"
                    initial={{ opacity: 0, rotate: 0 }}
                    animate={{
                      opacity: handleAnimation(item) ? 1 : 0,
                      rotate: handleAnimation(item) ? 0 : -45,
                    }}
                    transition={{ duration: 0.4 }}
                  />
                </div>
              </div>
              <div
                className={styles.accordionContent}
                style={{
                  height: item.isOpen ? `${accordionContentHeight + (!isMobile ? 25 : 12)}px` : 0,
                }}
              >
                <p className={styles.description}>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

FAQ.displayName = 'FAQ';

export default FAQ;
